'use client';
import { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { stateAbbreviationTitles } from '@/constants/states';
import { IDynamicsOptionSet } from '@/types/dynamicsForms';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';

import { CommonFormContext } from './';

interface StateGroupProps {
  stateAbbreviation: string;
  locations: {
    city: string;
    fullLocation: string;
  }[];
}

const StateGroup = ({ stateAbbreviation, locations }: StateGroupProps) => (
  <div className="py-1" role="none" key={stateAbbreviation}>
    <label
      className="block px-4 py-2 text-sm font-bold uppercase text-gray-900"
      htmlFor={stateAbbreviationTitles[stateAbbreviation]}
    >
      {stateAbbreviationTitles[stateAbbreviation]}
    </label>
    {locations.map((location) => (
      <ListboxOption
        className="px-4 py-2 text-sm text-black-500 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
        value={location.fullLocation}
        key={location.city}
      >
        {location.city}
      </ListboxOption>
    ))}
  </div>
);

interface CommonLocationSelectProps {
  locationsOptionSet: IDynamicsOptionSet;
  containerClassName?: string;
  label: string;
  isRequired?: boolean;
}

export const CommonLocationSelect = ({
  locationsOptionSet,
  label,
  containerClassName,
  isRequired,
}: CommonLocationSelectProps) => {
  const { isDisabled } = useContext(CommonFormContext);

  const stateLocations = locationsOptionSet.reduce(
    (acc, option) => {
      const [city, stateAbbreviation] = option.Label.split(',').map((part) => part.trim());

      if (!stateAbbreviation || !stateAbbreviationTitles[stateAbbreviation]) return acc;

      if (!acc[stateAbbreviation]) acc[stateAbbreviation] = [];

      acc[stateAbbreviation].push({ city, fullLocation: option.Label });

      return acc;
    },
    {} as Record<
      string,
      {
        city: string;
        fullLocation: string;
      }[]
    >,
  );

  return (
    <div className={twMerge('flex flex-col gap-1.5', isDisabled && 'opacity-60', containerClassName)}>
      <span className="text-sm text-black-500">
        {label}
        {isRequired && <span className="text-red-700"> *</span>}
      </span>
      <Controller
        name="location"
        defaultValue=""
        render={({ field, fieldState }) => (
          <>
            {/* this hidden input is required for dynamics forms capture */}
            <input type="hidden" name={field.name} value={field.value} />
            <Listbox value={field.value} onChange={field.onChange}>
              {({ open }) => (
                <div className="relative">
                  <ListboxButton
                    className={twMerge(
                      'border border-black-200 rounded-sm px-3 py-2 text-black-500 w-full outline-none',
                      fieldState.error && 'border-red-700 placeholder-red-400 text-red-700',
                      open && 'ring-1 ring-blue-600',
                    )}
                  >
                    <span
                      className={twMerge(
                        'block truncate text-start',
                        field.value ? 'text-black-500' : 'text-black-350',
                      )}
                    >
                      {field.value || 'Select a location'}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className={twMerge('h-4 w-4 text-black-350 transition', open && 'rotate-180')}
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <Transition
                    show={open}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <ListboxOptions
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      modal={false}
                      className="absolute left-0 z-10 mt-2 w-80 origin-top-right divide-y divide-black-100 rounded bg-white shadow-lg border border-black-100 focus:outline-none"
                    >
                      {Object.entries(stateLocations)
                        .sort(([stateAbbreviationA], [stateAbbreviationB]) =>
                          stateAbbreviationTitles[stateAbbreviationA].localeCompare(
                            stateAbbreviationTitles[stateAbbreviationB],
                          ),
                        )
                        .map(([stateAbbreviation, locations]) => (
                          <StateGroup
                            key={stateAbbreviation}
                            stateAbbreviation={stateAbbreviation}
                            locations={locations}
                          />
                        ))}
                    </ListboxOptions>
                  </Transition>
                </div>
              )}
            </Listbox>
          </>
        )}
      />
    </div>
  );
};
