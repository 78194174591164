'use client';

import { HTMLInputTypeAttribute, useContext } from 'react';
import { FieldError, get, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { CommonFormContext } from './';

interface CommonInputProps {
  isRequired?: boolean;
  name: string;
  label: string;
  placeholder?: string;
  containerClassName?: string;
  type?: HTMLInputTypeAttribute;
}

export const CommonInput = ({
  label,
  name,
  isRequired,
  placeholder,
  containerClassName,
  type = 'text',
}: CommonInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { isDisabled } = useContext(CommonFormContext);

  const error = get(errors, name) as FieldError | null;

  return (
    <div className={twMerge('flex flex-col gap-1.5', isDisabled && 'opacity-60', containerClassName)}>
      <span className="text-sm text-black-500">
        {label}
        {isRequired && <span className="text-red-700"> *</span>}
      </span>
      <input
        type={type}
        className={twMerge(
          'border border-black-200 rounded-sm px-3 py-2 placeholder-black-350 text-black-500 w-full',
          error && 'border-red-700 placeholder-red-400 text-red-700',
        )}
        placeholder={placeholder}
        {...register(name)}
        disabled={isDisabled}
      />
      {error && <span className="text-red-700 text-sm">{error.message}</span>}
    </div>
  );
};
