'use client';

import { PropsWithChildren, useContext } from 'react';
import { FieldError, get, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { PropsWithClasses } from '@lamesarv-sdk/types';

import { CommonFormContext } from './';

interface CommonCheckboxProps extends PropsWithClasses {
  name: string;
  containerClassName?: string;
}

export const CommonCheckbox = ({
  name,
  className,
  containerClassName,
  children,
}: PropsWithChildren<CommonCheckboxProps>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { isDisabled } = useContext(CommonFormContext);

  const error = get(errors, name) as FieldError | null;

  return (
    <div className={twMerge('flex flex-col gap-1.5', containerClassName)}>
      <div className={twMerge('flex items-center gap-2', className)}>
        <input
          type="checkbox"
          {...register(name)}
          className="border border-black-350 size-3 disabled:opacity-60"
          disabled={isDisabled}
        />
        <span className="text-sm text-black-500">{children}</span>
      </div>

      {error && <span className="text-red-700 text-sm">{error.message}</span>}
    </div>
  );
};
