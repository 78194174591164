import { useContext, useEffect } from 'react';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { twMerge } from 'tailwind-merge';
import * as Yup from 'yup';

import { LayoutContext } from '@/contexts/LayoutContext';
import { useDynamicsForms } from '@/hooks/dynamicsForms';
import { useEnv } from '@/hooks/useEnv';
import { parsePhone } from '@/tools/dynamics';
import { getUnlockPriceLabel } from '@/tools/inventory';
import { DynamicsLeadType, IDynamicsOptionSet } from '@/types/dynamicsForms';
import { faCheckCircle, faClock } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faExclamationTriangle, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { phoneRegex } from '@lamesarv-sdk/tools';
import { IBasicInventory, InventoryField } from '@lamesarv-sdk/types';

interface IInventoryForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  zipCode: string;
  comments: string;
  terms: boolean;
}

const schema = Yup.object().shape(
  {
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string().when('phone', {
      is: (value: string) => !!value?.length,
      then: (rule) => rule.matches(phoneRegex, 'Please enter a valid phone number.'),
    }),
    comments: Yup.string(),
    terms: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
    zipCode: Yup.string().length(5, 'Please enter a 5 digit zip code').required('Zip is required'),
  },
  [['phone', 'phone']],
);

interface InventoryFormProps {
  item: IBasicInventory;
  locationsOptionSet?: IDynamicsOptionSet;
  onSuccess?: () => void;
}

export const InventoryForm = ({ item, locationsOptionSet, onSuccess }: InventoryFormProps) => {
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<IInventoryForm>({ resolver: yupResolver(schema) as unknown as Resolver<IInventoryForm> });
  const { loading, submitted, error, sendForm } = useDynamicsForms();
  const {
    context: { detailsSettings: settings, inventoryLocations: locations },
  } = useContext(LayoutContext);

  useEffect(() => {
    const wasSuccessful = submitted && !error;

    if (wasSuccessful && onSuccess) onSuccess();
  }, [submitted, error]);

  const location = item[InventoryField.location]
    ? locations?.find((location) => location.aliases.includes(item[InventoryField.location].toLowerCase().trim()))
    : undefined;

  const { env } = useEnv();

  const onSubmit: SubmitHandler<IInventoryForm> = async (formInput) => {
    const formMappings = [
      {
        FormFieldName: 'leadType',
        DataverseFieldName: 'lmrv_leadtype',
        DataverseFieldValue: [
          { FormValue: 'buyer', DataverseValue: DynamicsLeadType.buyer }, // Buyer
          { FormValue: 'seller', DataverseValue: DynamicsLeadType.seller }, // Seller
          { FormValue: 'service', DataverseValue: DynamicsLeadType.service }, // Service
          { FormValue: 'parts', DataverseValue: DynamicsLeadType.parts }, // Parts
        ],
      },
      {
        DataverseFieldName: 'lmrv_stocknumber',
        DataverseFieldValue: item[InventoryField.stockNumber]?.toLowerCase() || '',
      },
      { FormFieldName: 'firstName', DataverseFieldName: 'firstname' },
      { FormFieldName: 'lastName', DataverseFieldName: 'lastname' },
      { FormFieldName: 'email', DataverseFieldName: 'emailaddress1' },
      { DataverseFieldValue: parsePhone(formInput.phone), DataverseFieldName: 'mobilephone' },
      { FormFieldName: 'zipCode', DataverseFieldName: 'address1_postalcode' },
      { FormFieldName: 'comments', DataverseFieldName: 'lmrv_comments' },
      {
        FormFieldName: 'terms',
        DataverseFieldName: 'lmrv_accepttermsandconditions',
        DataverseFieldValue: [
          { FormValue: 'on', DataverseValue: '753740000' }, // Yes
          { FormValue: '', DataverseValue: '753740001' }, // No
        ],
      },
      {
        FormFieldName: 'location',
        DataverseFieldName: 'lmrv_nearestlocation',
        DataverseFieldValue: locationsOptionSet?.map(({ DataverseValue, Label }) => ({
          DataverseValue,
          FormValue: `${Label}`,
        })),
      },
      {
        FormFieldName: 'newsletter',
        DataverseFieldName: 'msdynmkt_topicid;channels;optinwhenchecked',
        DataverseFieldValue: 'f5174516-c98b-ef11-ac21-6045bdd2edd0;Email,Text;true',
      },
    ];

    await Promise.all([
      sendForm({
        formId: '#formUnlockTodaysPrice',
        mappings: formMappings,
        dynamicsFormId: env.DYNAMICS_FORM_ID_PDP,
        callback: () => {
          reset();
          reset({ phone: '' });
        },
      }),
    ]);
  };

  const { buttonLabel, subtext } = getUnlockPriceLabel(item);

  return (
    <>
      {location && (
        <>
          <div className="flex flex-row gap-2 items-start">
            <FontAwesomeIcon icon={faLocationDot} className="h-8" />
            <div className="flex flex-col gap-0.5 leading-none">
              <div className="text-sm font-bold uppercase" data-testid="location-title">
                {location.title}
              </div>
              {location.address && (
                <div className="text-xs" data-testid="location-subtitle">
                  {location.address}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col xl:flex-row justify-center md:justify-between divide-y xl:divide-y-0 xl:divide-x divide-neutral-200 border-t border-b border-neutral-200 my-4">
            <div className="flex flex-row gap-2 text-sm py-2 px-3">
              <span className="uppercase">Call Us At</span>
              <span className="text-blue-500 font-bold">877-641-8052</span>
            </div>
            <a
              href={location ? `/${location.route}` : '#'}
              className="hidden md:flex flex-row gap-1 py-2 px-3 hover:underline hover:underline-offset-4"
            >
              <span className="text-sm uppercase" data-testid="get-directions-link">
                Get Directions
              </span>
              <FontAwesomeIcon icon={faArrowRight} className="h-4" />
            </a>
          </div>
        </>
      )}

      <h2 className="uppercase text-center text-xl sm:text-2xl font-bold text-blue-700" data-testid="form-h2">
        {buttonLabel}
      </h2>
      <h3 className="mb-6 w-full text-center text-sm sm:text-base font-semibold text-blue-400" data-testid="form-h3">
        {subtext}
      </h3>
      {/* BEGIN:Form */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4 py-4"
        data-tag-group="inventory"
        data-tag="lock-price"
      >
        <div className="flex flex-col md:flex-row gap-3 justify-between">
          <div className="flex flex-col gap-1 flex-1">
            <div className="text-xs">
              First Name<span className="text-red-500"> *</span>
            </div>
            <input
              type="text"
              className={twMerge(
                'w-full py-2 px-4 text-sm border border-neutral-200',
                errors.firstName ? 'border-red-300 bg-red-100' : '',
              )}
              placeholder="First Name"
              {...register('firstName')}
            />

            {errors.firstName && <div className="text-xs text-red-500">{errors.firstName.message}</div>}
          </div>
          <div className="flex flex-col gap-1 flex-1">
            <div className="text-xs">
              Last Name<span className="text-red-500"> *</span>
            </div>
            <input
              type="text"
              className={twMerge(
                'w-full py-2 px-4 text-sm border border-neutral-200',
                errors.lastName ? 'border-red-300 bg-red-100' : '',
              )}
              placeholder="Last Name"
              {...register('lastName')}
            />

            {errors.lastName && <div className="text-xs text-red-500">{errors.lastName.message}</div>}
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <div className="text-xs">
            Email address<span className="text-red-500"> *</span>
          </div>
          <input
            type="email"
            className={twMerge(
              'w-full py-2 px-4 text-sm border border-neutral-200',
              errors.email ? 'border-red-300 bg-red-100' : '',
            )}
            placeholder="example@email.com"
            {...register('email')}
          />

          {errors.email && <div className="text-xs text-red-500">{errors.email.message}</div>}
        </div>
        <div className="flex flex-col gap-1">
          <div className="text-xs">Phone number</div>

          <ReactInputMask
            mask="(999) 999-9999"
            type="tel"
            {...register('phone')}
            placeholder="Phone"
            className={twMerge(
              'w-full py-2 px-4 text-sm border border-neutral-200',
              errors.phone ? 'border-red-300 bg-red-100' : '',
            )}
          />

          {errors.phone && <div className="text-xs text-red-500">{errors.phone.message}</div>}
        </div>

        <div className="flex flex-col gap-1">
          <div className="text-xs">
            Zip Code<span className="text-red-500"> *</span>
          </div>
          <input
            type="number"
            className={twMerge(
              'w-full py-2 px-4 text-sm border border-neutral-200',
              errors.zipCode ? 'border-red-300 bg-red-100' : '',
            )}
            placeholder="Zip Code"
            {...register('zipCode')}
          />

          {errors.zipCode && <div className="text-xs text-red-500">{errors.zipCode.message}</div>}
        </div>

        <div className="flex flex-col gap-1">
          <div className="text-xs">Comments</div>
          <textarea
            className={twMerge(
              'w-full py-2 px-4 text-sm border border-neutral-200',
              errors.comments ? 'border-red-300 bg-red-100' : '',
            )}
            placeholder="Comments"
            {...register('comments')}
          />

          {errors.comments && <div className="text-xs text-red-500">{errors.comments.message}</div>}
        </div>

        <div className="py-2">
          <div className="flex flex-row items-start gap-2 mb-1">
            <input type="checkbox" className="w-3 h-3 mt-1 text-blue-600 focus:ring-blue-600" {...register('terms')} />
            <div className="text-sm" dangerouslySetInnerHTML={{ __html: settings.legalCheckboxContent }} />
          </div>

          {errors.terms && <div className="text-xs text-red-500">{errors.terms.message}</div>}
        </div>

        {loading && (
          <div
            className={
              'flex items-center w-full bg-gray-100 border-l-4 border-gray-500 text-gray-700 py-2 px-3 mb-4 col-span-3'
            }
            role={'alert'}
          >
            <FontAwesomeIcon icon={faClock} className="w-6 mr-2" />
            <span>Sending...</span>
          </div>
        )}
        {submitted && !error && (
          <div
            className={
              'flex items-center w-full bg-green-100 border-l-4 border-green-500 text-green-700 py-2 px-3 mb-4 col-span-3'
            }
            role={'alert'}
          >
            <FontAwesomeIcon icon={faCheckCircle} className="w-6 mr-2" />
            <span>Thank you for requesting more information.</span>
          </div>
        )}
        {submitted && error && (
          <div
            className={
              'flex items-center w-full bg-red-100 border-l-4 border-red-500 text-red-700 py-2 px-3 mb-4 col-span-3'
            }
            role={'alert'}
          >
            <div>
              <FontAwesomeIcon icon={faExclamationTriangle} className="w-6 mr-2" />
            </div>

            <span>An error has occurred, please try again later or contact us using alternative methods.</span>
          </div>
        )}

        <div className="">
          <button
            type="submit"
            className="w-full cursor-pointer rounded-sm bg-[#1E1E1E] border border-transparent hover:border-black hover:bg-white hover:text-black px-4 py-3 text-center text-sm font-bold uppercase text-white text-opacity-90 shadow-sm hover:shadow-md"
            data-tag-group="form-lock-price"
            data-tag="form-lock-price-submit"
            data-testid="form-submit-btn"
          >
            {buttonLabel}
          </button>
        </div>
      </form>
    </>
  );
};
