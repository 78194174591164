'use client';

import { Fragment } from 'react';
import { FieldError, get, useFormContext } from 'react-hook-form';

interface ICommonHiddenProps {
  name: string;
  value: string;
}

export const CommonHidden = (props: ICommonHiddenProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, props.name) as FieldError | null;

  return (
    <Fragment>
      <input type="hidden" {...register(props.name)} />
      {error && <span className="text-red-700 text-sm">{error.message}</span>}
    </Fragment>
  );
};
