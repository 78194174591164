import Link from "next/link";
import { PropsWithChildren, useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import * as Yup from "yup";

import { CommonForm } from "@/components/CommonForm";
import { CommonCheckbox } from "@/components/CommonForm/CommonCheckbox";
import { CommonHidden } from "@/components/CommonForm/CommonHidden";
import { CommonInput } from "@/components/CommonForm/CommonInput";
import { CommonLocationSelect } from "@/components/CommonForm/CommonLocationSelect";
import { CommonMaskInput } from "@/components/CommonForm/CommonMaskInput";
import { CommonTextarea } from "@/components/CommonForm/CommonTextarea";
import { InventoryFormContext } from "@/contexts/InventoryFormContext";
import { LayoutContext } from "@/contexts/LayoutContext";
import { useDynamicsForms } from "@/hooks/dynamicsForms";
import { useEnv } from "@/hooks/useEnv";
import { parsePhone } from "@/tools/dynamics";
import { getUnlockPriceLabel } from "@/tools/inventory";
import {
  DynamicsDealershipType,
  DynamicsLeadType,
  IDynamicsOptionSet
} from "@/types/dynamicsForms";
import {
  faArrowRight,
  faArrowUpRight,
  faCheck,
  faLocationDot,
  faSpinnerThird
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormApi } from "@lamesarv-sdk/hooks";
import { phoneRegex } from "@lamesarv-sdk/tools";
import {
  IBasicInventory,
  InventoryField,
  PropsWithTags
} from "@lamesarv-sdk/types";

interface IInventoryForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  location: string;
  comments: string;
  terms: boolean;
}

const schema = Yup.object().shape(
  {
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string().when('phone', {
      is: (value: string) => !!value?.length,
      then: (rule) => rule.matches(phoneRegex, 'Please enter a valid phone number.'),
    }),
    comments: Yup.string(),
    terms: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
    location: Yup.string().required('Location is required'),
  },
  [['phone', 'phone']],
);

interface FormResetterProps {
  wasSuccessful: boolean;
}

const FormResetter = ({ wasSuccessful }: FormResetterProps) => {
  const { reset } = useFormContext();

  useEffect(() => {
    if (!wasSuccessful) return;

    reset();
    reset({
      phone: '',
    });
  }, [wasSuccessful]);

  return null;
};

interface SubmitButtonProps extends PropsWithTags {
  className?: string;
  loading: boolean;
  wasSuccessful: boolean;
}

const SubmitButton = ({
  loading,
  wasSuccessful,
  className,
  children,
  ...props
}: PropsWithChildren<SubmitButtonProps>) => (
  <button
    type="submit"
    disabled={loading || wasSuccessful}
    className={twMerge(
      'rounded bg-blue-500 text-white font-bold py-3 flex items-center justify-center enabled:hover:bg-blue-600 relative',
      className,
    )}
    data-tag-group={props.tagItem ? `form-${props.tagItem}` : 'form'}
    data-tag={props.tagItem ? `form-${props.tagItem}-submit` : 'form-submit'}
  >
    <span className={twMerge((loading || wasSuccessful) && 'opacity-0')}>{children}</span>
    <div className="absolute top-0 left-0 size-full flex items-center justify-center">
      {loading && <FontAwesomeIcon icon={faSpinnerThird} className="animate-spin size-4" />}
      {wasSuccessful && <FontAwesomeIcon icon={faCheck} className="size-4" />}
    </div>
  </button>
);

interface InventoryFormProps {
  item: IBasicInventory;
  onSuccess?: () => void;
  locationsOptionSet?: IDynamicsOptionSet;
}

export const InventoryForm = ({ item, onSuccess, locationsOptionSet }: InventoryFormProps) => {
  const { env } = useEnv();

  const {
    context: { inventoryLocations: locations, detailsSettings: settings },
  } = useContext(LayoutContext);
  const { closeMobileInquire } = useContext(InventoryFormContext);

  const { subtext, buttonLabel } = getUnlockPriceLabel(item);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_loadingLegacy, _submittedLegacy, _errorLegacy, sendFormLegacy] = useFormApi();

  const { loading, submitted, error, sendForm } = useDynamicsForms();

  const wasSuccessful = !loading && !error && submitted;

  useEffect(() => {
    if (wasSuccessful && onSuccess) onSuccess();
  }, [wasSuccessful]);

  const handleFormSubmit = async (data: IInventoryForm) => {
    const locationItem = locations.find((location) => location.aliases.includes(data.location.toLowerCase().trim()));

    let zipCode = locationItem?.address?.trim().split(' ').pop() || '';
    if (zipCode.length !== 5) zipCode = '';

    const formMappings = [
      {
        FormFieldName: 'leadType',
        DataverseFieldName: 'lmrv_leadtype',
        DataverseFieldValue: [
          { FormValue: 'buyer', DataverseValue: DynamicsLeadType.buyer }, // Buyer
          { FormValue: 'seller', DataverseValue: DynamicsLeadType.seller }, // Seller
          { FormValue: 'service', DataverseValue: DynamicsLeadType.service }, // Service
          { FormValue: 'parts', DataverseValue: DynamicsLeadType.parts }, // Parts
        ],
      },
      {
        FormFieldName: 'leadSource',
        DataverseFieldName: 'leadsourcecode',
        DataverseFieldValue: [
          { FormValue: 'lamesarv', DataverseValue: '3' }, // lamesarv.com
        ],
      },
      {
        DataverseFieldName: 'lmrv_stocknumber',
        DataverseFieldValue: item[InventoryField.stockNumber]?.toLowerCase() || '',
      },
      {
        FormFieldName: 'firstName',
        DataverseFieldName: 'firstname',
      },
      {
        FormFieldName: 'lastName',
        DataverseFieldName: 'lastname',
      },
      {
        FormFieldName: 'email',
        DataverseFieldName: 'emailaddress1',
      },
      {
        DataverseFieldValue: parsePhone(data.phone),
        DataverseFieldName: 'mobilephone',
      },
      {
        DataverseFieldName: 'address1_postalcode',
        DataverseFieldValue: zipCode,
      },
      {
        FormFieldName: 'comments',
        DataverseFieldName: 'lmrv_comments',
      },
      {
        FormFieldName: 'terms',
        DataverseFieldName: 'lmrv_accepttermsandconditions',
        DataverseFieldValue: [
          { FormValue: 'on', DataverseValue: '753740000' }, // Yes
          { FormValue: '', DataverseValue: '753740001' }, // No
        ],
      },
      {
        FormFieldName: 'subscribe',
        DataverseFieldName: 'lmrv_subscribe',
        DataverseFieldValue: [
          { FormValue: 'on', DataverseValue: '1' }, // Yes
          { FormValue: '', DataverseValue: '0' }, // No
        ],
      },
      {
        FormFieldName: 'location',
        DataverseFieldName: 'lmrv_dealership',
        DataverseFieldValue: [
          { FormValue: 'Phoenix, AZ', DataverseValue: DynamicsDealershipType.phoenix }, // Phoenix, AZ
          { FormValue: 'Mesa, AZ', DataverseValue: DynamicsDealershipType.mesa }, // Mesa, AZ
          { FormValue: 'Tucson, AZ', DataverseValue: DynamicsDealershipType.tucson }, // Tucson, AZ
          { FormValue: 'Fremont, CA', DataverseValue: DynamicsDealershipType.fremont }, // Fremont, CA
          { FormValue: 'West Sacramento, CA', DataverseValue: DynamicsDealershipType.westSacramento }, // West Sacramento, CA
          { FormValue: 'San Diego, CA', DataverseValue: DynamicsDealershipType.sanDiego }, // San Diego, CA
          { FormValue: 'Albuquerque, NM', DataverseValue: DynamicsDealershipType.albuquerque }, // Albuquerque, NM
          { FormValue: 'Orlando (Sanford), FL', DataverseValue: DynamicsDealershipType.orlando }, // Orlando (Sanford), FL
          { FormValue: 'Port St. Lucie, FL', DataverseValue: DynamicsDealershipType.portStLucie }, // Port St. Lucie, FL
          { FormValue: 'Ft. Myers, FL', DataverseValue: DynamicsDealershipType.ftMyers }, // Ft. Myers, FL
          { FormValue: 'Davie, FL', DataverseValue: DynamicsDealershipType.davie }, // Davie, FL
        ],
      },
    ];

    if (env.ENABLE_FORMS_LEGACY) {
       sendFormLegacy({
        ...data,
        stockNumber: item[InventoryField.stockNumber]?.toLowerCase(),
        emailType: 'best-price',
        formId: '23',
        formType: 'more_info',
      });
    }

    await sendForm({
      formId: '#formUnlockTodaysPrice',
      mappings: formMappings,
      dynamicsFormId: env.DYNAMICS_FORM_ID_PDP,
    });
  };

  const location = item[InventoryField.location]
    ? locations?.find((location) => location.aliases.includes(item[InventoryField.location].toLowerCase().trim()))
    : undefined;

  return (
    <div className="flex flex-col items-center">
      {location && (
        <>
          <div className="flex items-center gap-1">
            <FontAwesomeIcon icon={faLocationDot} className="size-4 text-black-350 shrink-0" />
            <span className="font-bold text-sm lg:text-base xs:whitespace-nowrap">{location.title}</span>
          </div>

          <a
            target="_blank"
            rel="noreferrer"
            href={
              location?.address ? `https://www.google.com/maps/search/?q=${encodeURIComponent(location.address)}` : '#'
            }
            className="flex items-center gap-1 mt-2.5 hover:underline hover:decoration-blue-500"
          >
            <span className="text-sm text-blue-500 font-semibold">{location.address}</span>
            <FontAwesomeIcon icon={faArrowUpRight} className="size-4 text-blue-500 shrink-0" />
          </a>
        </>
      )}

      <div className="flex gap-2.5 lg:gap-3.5 mt-5">
        <a
          href="tel:877-641-8052"
          className="py-1.5 lg:py-2 px-2.5 lg:px-6 flex items-center justify-center gap-1.5 border border-black-200 rounded hover:bg-gray-50"
        >
          <span className="text-xs lg:text-sm text-black-500 whitespace-nowrap">Call us at</span>
          <span className="text-black-500 font-bold text-xs lg:text-sm whitespace-nowrap">877-641-8052</span>
        </a>
        <Link
          prefetch
          href={location ? `/${location.route}` : '#'}
          onClick={() => closeMobileInquire()}
          className="py-1.5 lg:py-2 px-2.5 lg:px-6 flex items-center justify-center gap-1.5 border border-black-200 rounded hover:bg-gray-50"
        >
          <span className="text-xs lg:text-sm text-black-500 whitespace-nowrap">Get Directions</span>
          <FontAwesomeIcon icon={faArrowRight} className="size-3.5 lg:size-4 text-black-500" />
        </Link>
      </div>

      <span className="font-bold text-blue-500 mt-2.5 text-sm lg:text-base text-center xs:whitespace-nowrap">
        {subtext}
      </span>

      <CommonForm
        id="formUnlockTodaysPrice"
        schema={schema}
        onSubmit={handleFormSubmit}
        className="flex flex-col gap-4 mt-5 lg:w-[46rem] max-md:mb-28"
        isDisabled={loading || wasSuccessful}
        tagGroup="inventory"
        tagItem="lock-price"
      >
        <CommonHidden name="leadType" value="buyer" />
        <CommonHidden name="leadSource" value="lamesarv" />
        <FormResetter wasSuccessful={wasSuccessful} />
        <div className="flex items-start gap-6">
          <CommonInput
            label="First Name"
            placeholder="First Name"
            name="firstName"
            isRequired
            containerClassName="flex-1"
          />
          <CommonInput
            label="Last Name"
            placeholder="Last Name"
            name="lastName"
            isRequired
            containerClassName="flex-1"
          />
        </div>
        <CommonInput label="Email Address" placeholder="example@email.com" name="email" type="email" isRequired />
        <div className="flex items-start gap-6">
          <CommonMaskInput
            mask="(999) 999-9999"
            label="Phone Number"
            placeholder="Phone"
            name="phone"
            containerClassName="flex-1"
          />
          <CommonLocationSelect
            label="Select store location"
            containerClassName="flex-1"
            locationsOptionSet={locationsOptionSet}
            isRequired
          />
        </div>
        <CommonTextarea label="Comments" name="comments" />
        <div className="flex flex-col gap-2 my-1">
          <CommonCheckbox name="terms">
            <div
              dangerouslySetInnerHTML={{
                __html: settings.legalCheckboxContent,
              }}
            />
          </CommonCheckbox>
          <CommonCheckbox name="subscribe" className="items-start">
            <div
              dangerouslySetInnerHTML={{
                __html: settings.subscribeCheckboxContent,
              }}
            />
          </CommonCheckbox>
        </div>

        {wasSuccessful && (
          <div className="flex items-center gap-2 mt-1">
            <FontAwesomeIcon className="text-green-500" icon={faCheck} />
            <span className="text-black-500 font-medium">Thank you for submitting the form</span>
          </div>
        )}

        {!wasSuccessful && (
          <>
            <SubmitButton
              loading={loading}
              wasSuccessful={wasSuccessful}
              className="max-md:hidden"
              tagGroup="inventory"
              tagItem="lock-price"
            >
              {buttonLabel}
            </SubmitButton>

            <div className="fixed md:hidden bottom-0 left-0 px-6 pt-5 pb-8 w-screen bg-white shadow-[0px_-4px_10px_0px_#00000033] z-50">
              <SubmitButton
                loading={loading}
                wasSuccessful={wasSuccessful}
                className="w-full"
                tagGroup="inventory"
                tagItem="lock-price"
              >
                {buttonLabel}
              </SubmitButton>
            </div>
          </>
        )}
      </CommonForm>
    </div>
  );
};
