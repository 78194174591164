'use client';

import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { CommonFormContext } from './';

interface CommonTextareaProps {
  label: string;
  name: string;
  placeholder?: string;
  containerClassName?: string;
}

export const CommonTextarea = ({ label, name, placeholder, containerClassName }: CommonTextareaProps) => {
  const { register } = useFormContext();
  const { isDisabled } = useContext(CommonFormContext);

  return (
    <div className={twMerge('flex flex-col gap-1.5', isDisabled && 'opacity-60', containerClassName)}>
      <span className="text-sm text-black-500">{label}</span>
      <textarea
        className="border border-black-200 rounded-sm px-3 py-2 placeholder-black-350 text-black-500 min-h-20"
        placeholder={placeholder}
        {...register(name)}
        disabled={isDisabled}
      />
    </div>
  );
};
