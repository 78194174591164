/* eslint-disable @typescript-eslint/no-explicit-any */

'use client';

import { useEnv } from '@/hooks/useEnv';
import { IBasicInventory, PropsWithTags } from '@lamesarv-sdk/types';

import { InventoryForm as NewInventoryForm } from './InventoryFormNew';
import { InventoryForm as OldInventoryForm } from './OldInventoryForm';

interface InventoryFormProps extends PropsWithTags {
  item: IBasicInventory;
  onSuccess?: () => void;
  locationsOptionSet?: any;
}

export const InventoryForm = (props: InventoryFormProps) => {
  const { env } = useEnv();

  if (env.ENABLE_NEW_PDP_DESIGN) return <NewInventoryForm {...props} />;

  return <OldInventoryForm {...props} />;
};
