import { createContext, PropsWithChildren, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import { PropsWithTags } from '@lamesarv-sdk/types';

interface CommonFormContextData {
  isDisabled?: boolean;
}

export const CommonFormContext = createContext({} as CommonFormContextData);

interface CommonFormProps extends PropsWithTags {
  id?: string;
  schema?: AnyObjectSchema;
  onSubmit: (data: unknown) => void;
  className?: string;
  isDisabled?: boolean;
}

export const CommonForm = ({
  children,
  schema,
  onSubmit,
  className,
  isDisabled,
  id,
  ...props
}: PropsWithChildren<CommonFormProps>) => {
  const formMethods = useForm({
    resolver: schema ? yupResolver(schema) : undefined,
  });

  const contextData = useMemo((): CommonFormContextData => ({ isDisabled }), [isDisabled]);

  return (
    <CommonFormContext.Provider value={contextData}>
      <form
        id={id}
        noValidate
        onSubmit={formMethods.handleSubmit(onSubmit)}
        className={className}
        data-tag-group={props.tagGroup || 'page-components'}
        data-tag={props.tagItem ? `form-${props.tagItem}` : 'form'}
      >
        <FormProvider {...formMethods}>{children}</FormProvider>
      </form>
    </CommonFormContext.Provider>
  );
};
